@import "@/assets/styles/global/lib.scss";





















































































































































































.intro-section {
  position: relative;
  .title-block {
    h4 {
      font-weight: 500;
      font-size: size(12);
      line-height: size(17);
      color: var(--rs-black-color);
      margin-bottom: size(10);
    }
    h2 {
      margin-bottom: size(40);
    }
  }
}
.image-block {
  display: none;
  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
  position: absolute;
  right: 0;
  bottom: size(-80);
  width: size(300);
  height: size(300);
  img {
    width: size(300);
    height: size(300);
  }
}
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  .popup {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: size(2);
    width: size(300);
    height: size(450);
    overflow: scroll;
    @media screen and (min-width: $breakpoint-md) {
      width: size(680);
      height: auto;
      overflow: auto;
    }
    .popup-header {
      svg {
        position: absolute;
        right: size(20);
        top: size(20);
        width: size(10);
        height: size(10);
        cursor: pointer;
      }
    }
  }
}
